import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Winner } from '../interfaces/tournament.interface';
import { AdminService } from '../services/admin.service';
import { AuthService } from '../services/auth.service';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  public winners$ = new Observable<Winner[]>();

  constructor(
    private adminService: AdminService,
    private authService: AuthService,
    private commonService: CommonService,
  ) {
    this.winners$ = this.adminService.getWinnersInfo();
  }

  ngOnInit() {
    this.adminService.refreshTournamentsInfo();
  }

  public approveAward(winner: Winner) {
    this.adminService.approveAward(winner, this.authService.adminID).subscribe();
  }

  public sortWinners(key: keyof Winner, order: 'asc' | 'desc' = 'asc') {
    this.winners$ = this.winners$.pipe(map(winners => this.commonService.OrderByArray(winners, key, order)));
  }
}
