import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Tournament, Winner } from '../interfaces/tournament.interface';
import { finalize } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AdminService {

  private tournaments$ = new BehaviorSubject<Tournament[]>([]);
  private winners$ = new BehaviorSubject<Winner[]>([]);

  constructor(
    private http: HttpClient
  ) {}

  public refreshTournamentsInfo(): void {
    this.http.get<Tournament[]>(`${environment.apiUrl}admin/tournaments`)
    .subscribe((tournaments: Tournament[]) => {
      this.tournaments$.next(tournaments);
      const rawWinners = [];
      tournaments.forEach(tournament => {
        this.mapTournamentId(tournament, tournament.winners);
        rawWinners.push(tournament.winners);
      });
      const winners = (rawWinners as any).flat();
      this.winners$.next(winners);
    });
  }

  private mapTournamentId(tournament: Tournament, winners: Winner[]) {
    winners.forEach(winner => winner.tournament_id = tournament.id);
  }

  public getWinnersInfo() {
    return this.winners$.asObservable();
  }

  public approveAward(winner: Winner, adminID: number) {
    const data = {
      is_awarding_approved: !winner.is_awarding_finalize,
      admin_id: adminID,
    };
    return this.http.post(`${environment.apiUrl}admin/changeApprovalStatus/${winner.tournament_id}/${winner.mlbb_name}`, data)
    .pipe(finalize(() => this.refreshTournamentsInfo()));
  }
}
