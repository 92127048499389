import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  public OrderByArray<T, K extends keyof T>(array: T[], key: K, order: 'asc' | 'desc' = 'asc') {
    return array.sort((a, b) => {
      let result = 0;
      if (a[key] < b[key]) {
          result = -1;
      }

      if (a[key] > b[key]) {
          result = 1;
      }

      return (
        (order === 'desc') ? (result * -1) : result
      );
    });
  }
}
