import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

/**
 * Loads app-config and sets it to environment
 *
 * This service have to be loaded and used BEFORE other parts of the app start.
 */
@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor(private injector: Injector) { }

  /**
   * Gets HttpClient instance, loads app-config.json and monkey patches current environment
   *
   * It returns a Promise, as it is requirement of Angular Initialization process - it waits until
   * all promises complete
   */
  public loadAppConfig() {
    const http = this.injector.get(HttpClient);

    return http.get('./app-config.json').toPromise().then((data: {apiUrl: string}) => {
      environment.apiUrl = data.apiUrl;
    });
  }
}
