import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  public login(name: string, password: string): Observable<any> {
    const user = {name, password};
    return this.http.post(`${environment.apiUrl}admin/${user.name}`, {password: user.password}).pipe(tap((response: any) => {
      localStorage.setItem('Bearer', response.access_token);
      localStorage.setItem('AdminID', response.user_id);
    }));
  }

  public logout(): void {
    localStorage.removeItem('Bearer');
    localStorage.removeItem('AdminID');
    this.router.navigate(['/login']);
  }

  public get token() {
    return localStorage.getItem('Bearer');
  }

  public get adminID() {
    return +localStorage.getItem('AdminID');
  }
}
