import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

function asyncObservable<T>(promise: Promise<Observable<T>>): Observable<T> {
  return from(promise).pipe(switchMap(x => x));
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    return asyncObservable(this.promiseIntercept(req, next));
  }

  private async promiseIntercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.auth.token;
    return next.handle(req.clone({setHeaders: {
      Authorization: 'Bearer ' + token,
    }}));
  }
}
